<script setup lang="ts">
import ReferralBanner from "~/components/TopBanner/Referrals/ReferralBanner.vue";
import PromoCodeTiers from "~/components/TopBanner/Promos/PromoCodeTiers.vue";
import ReferralRedemptionLimitBanner from "~/components/TopBanner/Referrals/ReferralRedemptionLimitBanner.vue";
import PromoBanner from "~/components/TopBanner/Promos/PromoBanner.vue";

useHead({
  link: [
    {
      rel: "stylesheet",
      href: "/assets/compile/tailwind-legacy.css",
    },
  ],
});
</script>
<template>
  <div class="flex flex-col min-h-screen text-green-500 h-auto">
    <client-only>
      <PromoCodeTiers/>
      <PromoBanner/>
    </client-only>
    <ReferralBanner/>
    <ReferralRedemptionLimitBanner/>
    <Header />
    <div
      class="flex-1 md:relative h-0 overflow-auto flex flex-col font-bookmania"
    >
      <div class="h-0 flex-1">
        <main id="main-content" tabindex="-1">
          <slot />
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>
